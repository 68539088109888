import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";

function TermsPage() {
  return (
    <>
      <Helmet>
        <title>Terms | Edclerk</title>
        <meta
          name="keywords"
          content="HB4545, restroom passes, hallway monitoring, PBIS, SEL, organize, positive, support, easy, teachers"
        />
        <meta
          name="description"
          content="Helping principals of public schools prevent compliance issues,
          organize compliance documents, and keep track of student behavior
          with cloud based software that notify teachers and mentors in a
          collaborative interface"
        />
        <meta name="icon" href="/favicon.ico" />
      </Helmet>
      <section className="section py-14">
        <div className="container mx-auto">
          <Header />
          <main className="mt-12">
            <h1 className="text-2xl mb-3">Terms and Conditions</h1>
            <h4 className="mb-3">Welcome to Document Daily!</h4>
            <p className="mb-3">
              These are the terms and conditions for:{" "}
              <a href="https://edclerk.com" className="underline text-blue">
                edclerk.com
              </a>{" "}
              property of Document Daily LLC. USA.
            </p>
            <p className="mb-3">
              Please read these terms and conditions carefully before accessing,
              using or obtaining any materials, information, products or
              services.
            </p>
            <p className="mb-3">
              By accessing the Document Daily website, (hereby "Our Website”),
              you agree to be bound by these terms and conditions ("Terms") and
              our Privacy Policy. If you do not accept all of these Terms, then
              you may not use Our Website. In these Terms, "we", "us", "our" and
              "Document Daily" refers to Document Daily LLC and "you" and “your"
              refers to you, the user of Document Daily.
            </p>
            <h4 className="font-bold">DOCUMENT DAILY</h4>
            <p className="mb-3">
              Document Daily is a website that provides information,
              documentation and resources for Admins, Teachers, Special Ed,
              Counselors, Specialists and Coaches. The documentation available
              in this website, will serve as support material for educational
              work.
            </p>
            <p className="mb-3">
              Document Daily offers the following services:
            </p>
            <ul className="list-disc">
              <li>Ed-Tech Educational Documentation.</li>
              <li>Documentation of services.</li>
              <li>
                Accountability for but not limited to Special Education, RTI,
                504 and GT.
              </li>
            </ul>
            <h4 className="font-bold mt-3 mb-3">
              USAGE OF DOCUMENT DAILY WEBSITE
            </h4>
            <p className="mb-3">
              The information and materials available through Document Daily
              website are for informational and educational purposes only and
              are not a substitute for the professional judgment of legal,
              financial or health care professionals. The medical, scientific,
              legal, reimbursement and general information included on Document
              Daily website may reflect innovations and opinions not universally
              shared and does not necessarily reflect the view of Document
              Daily. Your use of Document Daily website does not create an
              attorney-client or doctor-patient relationship between you and
              Document Daily or between you and any of Document Daily employees
              or representatives.
            </p>
            <p className="mb-3">
              USE OF THE INFORMATION CONTAINED ON DOCUMENT DAILY WEBSITE
              REQUIRES THE INVOLVEMENT OF TRAINED INDIVIDUALS AND YOUR RELIANCE
              UPON INFORMATION AND MATERIALS OBTAINED BY YOU AT OR THROUGH
              DOCUMENT DAILY WEBSITE OR FROM DOCUMENT DAILY STAFF IS SOLELY AT
              YOUR OWN RISK. DOCUMENT DAILY HAS NO CONTROL OR RESPONSIBILITY FOR
              YOUR USE OF THE INFORMATION OR MATERIALS PROVIDED ON DOCUMENT
              DAILY WEBSITE AND HAS NO KNOWLEDGE OF THE SPECIFIC OR UNIQUE
              CIRCUMSTANCES UNDER WHICH SUCH INFORMATION MAY BE USED BY YOU. THE
              PARTIES AGREE THAT DOCUMENT DAILY IS NOT ENGAGED IN THE PRACTICE
              OF MEDICINE AND DOCUMENT DAILY DOES NOT PROVIDE LEGAL ADVICE. YOUR
              USE OF THE INFORMATION PROVIDED ON DOCUMENT DAILY WEBSITE IS NOT A
              SUBSTITUTE FOR LEGAL ADVICE OR PROFESSIONAL MEDICAL JUDGMENT.{" "}
            </p>
            <h4 className="font-bold mb-3">PROHIBITED ACTIVITIES</h4>
            <ol className="list-decimal">
              <li>
                The content, documents and information available on Document
                Daily (including, but not limited to, data, information, text,
                photos, graphics, video, icons, software, code or other
                material), as well as the infrastructure used to provide such
                Content and information, is proprietary to Document Daily or
                licensed to the Document Daily by third parties. For all content
                other than your content, you agree not to otherwise modify,
                copy, distribute, transmit, display, perform, reproduce,
                publish, license, create derivative works from, transfer, or
                sell or re-sell any information, software, products, or services
                obtained from or through the Services. Additionally, you agree
                not to:
              </li>
              <li>
                Use the services or content for any commercial purpose, outside
                the scope of those commercial purposes explicitly permitted
                under this agreement and related guidelines as made available by
                Document Daily;
              </li>
              <li>
                Access, monitor, reproduce, distribute, transmit, broadcast,
                display, sell, license, copy or otherwise exploit any Content of
                the Services, including but not limited to, user profiles and
                photos, using any robot, spider, scraper or other automated
                means or any manual process for any purpose not in accordance
                with this Agreement or without our express written permission;
              </li>
              <li>
                Violate the restrictions in any robot exclusion headers on the
                Services or bypass or circumvent other measures employed to
                prevent or limit access to the Services;
              </li>
              <li>
                Take any action that imposes, or may impose, in our discretion,
                an unreasonable or disproportionately large load on our
                infrastructure;
              </li>
              <li>
                Deep-link to any portion of the Services for any purpose without
                our express written permission;
              </li>
              <li>
                "Frame", "mirror" or otherwise incorporate any part of the
                Services into any other websites or service without our prior
                written authorization;
              </li>
              <li>
                Attempt to modify, translate, adapt, edit, decompile,
                disassemble, or reverse engineer any software programs used by
                Document Daily in connection with the Services;
              </li>
              <li>
                Circumvent, disable or otherwise interfere with security-related
                features of the Services or features that prevent or restrict
                use or copying of any Content; or
              </li>
              <li>
                Download any Content unless it’s expressly made available for
                download by Document Daily.
              </li>
            </ol>
            <h4 className="mb-3 font-bold mt-3">COPYRIGHT INFORMATION</h4>
            <p className="mb-3">
              You may use our website and services for personal, non-commercial,
              lawful purposes only. Our website contains copyrighted material,
              trademarks and other proprietary information, including, but not
              limited to, software, text, graphics and images (collectively, the
              “Content”). We may own the Content or portions of the Content may
              be made available to us through arrangements that we have with
              third parties. The Content is protected by United States and
              foreign intellectual property laws. Unauthorized use of the
              Content may result in violation of copyright, trademark or other
              laws. You have no rights in the Content, and no copying,
              redistribution, retransmission, publication or commercial or
              non-commercial use of the Content will be permitted without our
              express written permission. The use or posting of any of the
              Content on any other website or in a networked computer
              environment for any purpose is expressly prohibited. You must
              retain all copyright and other proprietary notices contained in
              the original Content on any copy you make of the Content. For
              clarity, there is no prohibition against you maintaining
              hyperlinks on your own website(s) that lead visitors to a page on
              our website.
            </p>
            <h4 className="font-bold mb-3">TRADEMARKS</h4>
            <p className="mb-3">
              The trademarks, service marks, and logos of Document Daily used
              and displayed on this Site are registered and unregistered
              trademarks or service marks of Document Daily, other company,
              product, and service names located on our website may be
              trademarks or service marks owned by third parties. Nothing on our
              website or in this Agreement should be construed as granting, by
              implication, estoppel, or otherwise, any license or right to use
              any Trademark displayed on our website without our prior written
              consent specific for each such use. The Trademarks may not be used
              to disparage us or the applicable third party, or their respective
              products or services, or in any manner that may damage any
              goodwill in the Trademarks. All goodwill generated from the use of
              any Company Trademark shall inure to our benefit.
            </p>
            <h4 className="font-bold mb-3">ELECTRONIC COMMUNICATIONS</h4>
            <p className="mb-3">
              When you visit the Document Daily website and send emails to us,
              you are communicating with us electronically and thus consent to
              receive communications from us electronically. We will communicate
              with you by email or by posting notices on this website. You agree
              that all agreements, notices, disclosures, and other
              communications that we provide to you electronically satisfy any
              legal requirement that such communications be in writing.
            </p>
            <h4 className="font-bold mb-3">DELETE AND EDIT INFORMATION</h4>
            <p className="mb-3">
              We reserve the right, but undertake no duty, in our sole
              discretion, with or without notice, to review, edit, move, add,
              delete, or otherwise change any Content available on or through,
              or downloadable from the Document Daily website, including without
              limitation any Content in your account, or any of your messages,
              posts, or threads.
            </p>
            <h4 className="font-bold mb-3">LIABILITY</h4>
            <p className="mb-3">
              We (together with our officers, directors, employees,
              representatives, shareholders, affiliates, and providers), to the
              extent permitted by law, hereby expressly exclude any
              responsibility and liability for (a) any loss or damages to, or
              viruses that may infect, your computer equipment or other property
              as the result of your access to Our Website, your downloading of
              any content from Our Website or use of Tips or recommendations
              available on this website (b) any injury; death; loss; claim; act
              of god; accident; delay; or any direct, special, exemplary,
              punitive, indirect, incidental or consequential damages of any
              kind (including—without limitation—lost profits or lost savings),
              whether based in contract, tort, strict liability or otherwise,
              that arise out of or is in any way connected with: (i) any use of
              Our Website or our content; (ii) any failure or delay; Some states
              or countries do not allow this limitation of liability, so the
              limitations above may not apply or apply only partially to you.
            </p>
            <p className="mb-3">
              THESE TERMS AND CONDITIONS AND FOREGOING LIABLITY DISCLAIMER DO
              NOT AFFECT MANDATORY LEGAL RIGHTS THAT CANNOT BE EXCLUDED UNDER
              APPLICABLE LAW, FOR EXAMPLE UNDER CONSUMER PROTECTION LAWS IN
              PLACE IN CERTAIN COUNTRIES.
            </p>
            <p className="mb-3">
              IF THE LAW OF THE COUNTRY WHERE YOU LIVE DOES NOT ALLOW ANY
              PARTICULAR LIMITATION OR EXCLUSION OF LIABILITY PROVIDED FOR IN
              THIS CLAUSE, THAT LIMITATION WILL NOT APPLY. THE LIABILITY
              DISCLAIMER WILL OTHERWISE APPLY TO THE MAXIMUM EXTENT ALLOWED BY
              YOUR LOCAL LAW.
            </p>
            <h4 className="font-bold mb-3">INDEMNIFICATION</h4>
            <p className="mb-3">
              You agree to defend and indemnify Document Daily and any of their
              officers, directors, employees and agents from and against any
              claims, causes of action, demands, recoveries, losses, damages,
              fines, penalties or other costs or expenses of any kind or nature
              including but not limited to reasonable legal and accounting fees,
              brought by third parties as a result of:
            </p>
            <ul className="list-disc">
              <li>
                Your breach of this Agreement or the documents referenced
                herein.
              </li>
              <li>Your violation of any law or the rights of a third party.</li>
              <li>Your use of the Document Daily website.</li>
            </ul>
            <h4 className="font-bold mt-3 mb-3">CHANGES AND TERMINATION</h4>
            <p className="mb-3">
              We may change the site and these Terms at any time, in our sole
              discretion and without notice to you. You are responsible for
              remaining knowledgeable about these Terms. Your continued use of
              the site constitutes your acceptance of any changes to these Terms
              and any changes will supersede all previous versions of the Terms.
              Unless otherwise specified herein, all changes to these Terms
              apply to all users take effect. Furthermore, we may terminate this
              agreement with you under these Terms at any time by notifying you
              in writing (including by email) or without any warning.
            </p>
            <h4 className="font-bold mb-3">INTEGRATION CLAUSE</h4>
            <p className="mb-3">
              This Agreement together with the Privacy Policy and any other
              legal notices published by Document Daily, shall constitute the
              entire agreement between you and Document Daily concerning and
              governs your use of the website.
            </p>
            <h4 className="font-bold mb-3">FINAL PROVISIONS</h4>
            <p className="mb-3">
              These Terms are governed by USA law. Use of our website is
              unauthorized in any jurisdiction that does not give effect to all
              provisions of these Terms, including, without limitation, this
              paragraph.
            </p>
            <p className="mb-3">
              Our performance of these Terms is subject to existing laws and
              legal process, and nothing contained in these Terms limits our
              right to comply with law enforcement or other governmental or
              legal requests or requirements relating to your use of our
              Platform or information provided to or gathered by us with respect
              to such use.
            </p>
            <p className="mb-3">
              If any part of these Terms is found to be invalid, illegal or
              unenforceable, the validity, legality and enforceability of the
              remaining provisions will not in any way be affected or impaired.
              Our failure or delay in enforcing any provision of these Terms at
              any time does not waive our right to enforce the same or any other
              provision(s) hereof in the future.
            </p>
            <p className="mb-3">
              Any rights not expressly granted herein are reserved.
            </p>
          </main>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TermsPage;